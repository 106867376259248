import { Injectable } from '@angular/core';
// import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../includes/global';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface QuestionaireResult {
  question_id: any,
  reasons: any, chads_scrore: any, measure: any, answer: string
}
@Injectable({
  providedIn: 'root',
})
export class MipsService {
  MEASURE_NUMEBRS = [
    "47",
    "110",
    "111",
    "126",
    // "154",
    "155",
    "181",
    "282",
    "283",
    "290",
    "293",
    // "286",
    "318",
    "386",
  ];

  MEASURE_NAMES = {
    "47": "Care Plan",
    "110": "Preventive Care and Screening: Influenza immunization",
    "111": "Pneumococcal Vaccination Status for Older Adults",
    "126": "Diabetic Foot and Ankle Care, Peripheral Neuropathy",
    "154": "Falls-Risk Assessment",
    "155": "Falls-Plan of Care",
    "181": "Elder Maltreament Screen and Follow-Up Plan",
    "282": "Dementia: Functional Status Assessment",
    "283": "Dementia: Associated Behavioral and Psychiatric Symptoms Screening and Management",
    "290": "Assessment of Mood Disorders and Psychosis",
    "293": "Parkinson Disease: Rehabilitative Therapy Options",
    "318": "Falls - Screening for Future Fall Risk",
    "386": "Amytrophic Lateral Sclerosis(ALS) Patient Care Preference",
  }

  MIPS = this.MEASURE_NUMEBRS.map((measure_number: string) => {
    return {
      measure_number,
      measure_name: this.MEASURE_NAMES[measure_number]
    }
  });

  questionnaireResults: BehaviorSubject<QuestionaireResult[]> = new BehaviorSubject([]);
castQuestionnaireResults = this.questionnaireResults.asObservable()
  currentUser: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.currentUser = this.authService.currentUser
  }
  getQuestionCounts(company_id) {
    const details = {
      ...this.authService.authObject,
      company_id,
    };
    return this.http.post(global.url + API_URL.MIPS.getQuestionCounts, { details });
  }
  getAllMips(companyId?) {
    const details = {
      ...this.authService.authObject,
      company_id: companyId,
    };
    return this.http.post(global.url + API_URL.MIPS.getAllMips, { details });
  }
  saveMipsSetting(value, companyId, mip) {
    const details = {
      ...this.authService.authObject,
      company_id: companyId,
      active_status: value,
      mip: mip
    };
    return this.http.post(global.url + API_URL.MIPS.saveMipsSetting, { details });
  }

  UploadMIPSDoc(file, company_id, mip_id) {
    const payload = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      title: file.name,
      mip_id: mip_id,
      company_id: company_id,
    };
    const formData: any = new FormData();
    formData.append('file', file);

    for (let key of Object.keys(payload)) {
      formData.append(key, payload[key]);
    }
    return this.http.post(global.url + API_URL.MIPS.UploadMIPSDoc, formData);
  }

  enableCompanyMips(status, companyId) {
    const details = {
      ...this.authService.authObject,
      company_id: companyId,
      active_status: status,
    };
    return this.http.post(global.url + API_URL.MIPS.enableCompanyMips, { details });
  }
  addQuestion(measure_number, parent_question_id, question, answer, company_id, condition?, data_source?, performance_type?, modifier?) {
    const details = {
      ...this.authService.authObject,
      company_id,
      measure_number,
      parent_question_id,
      question,
      answer,
      condition, data_source, performance_type, modifier
    };
    return this.http.post(global.url + API_URL.MIPS.addQuestion, { details });
  }

  updateMIPSQuestion(company_id, mips_id, mipsQuestion) {
    let details: any = {
      ...this.authService.authObject,
      company_id,
      mips_id,
      mipsQuestion,
    };
    return this.http.post(global.url + API_URL.MIPS.updateMIPSQuestion, { details });
  }

  updateQuestion(mips_question_id, measure_number, question, company_id, is_default?, condition?, data_source?, performance_type?, modifier?) {
    let details: any = {
      ...this.authService.authObject,
      company_id,
      mips_question_id,
      measure_number,
      question,
    };
    if (is_default !== undefined) {
      details.is_default = is_default;
    }
    return this.http.post(global.url + API_URL.MIPS.updateQuestion, { details });
  }
  getQuestions(measure_number, parent_question_id, company_id) {
    const details = {
      ...this.authService.authObject,
      company_id,
      measure_number,
      parent_question_id
    };
    return this.http.post(global.url + API_URL.MIPS.getQuestions, { details });
  }
  getMipsDetails(company_id, data) {
    data['company_id'] = company_id;
    return this.http.post(global.url + API_URL.MIPS.getMipsDetails, { data })
  }
  deleteQuestion(mips_question_id, company_id) {
    const details = {
      ...this.authService.authObject,
      company_id,
      mips_question_id,
    };
    return this.http.post(global.url + API_URL.MIPS.deleteQuestion, { details });
  }
  updateQuestionsSortOrder(mips_questions, company_id) {
    const details = {
      ...this.authService.authObject,
      company_id,
      mips_questions,
    };
    return this.http.post(global.url + API_URL.MIPS.updateQuestionsSortOrder, { details });
  }
  calculateDenominator(patient_id, cpt_ids, icd_ids, dos, provider_id, company_id, charge_id = null) {
    const details = {
      ...this.authService.authObject,
      company_id,
      patient_id,
      cpt_ids,
      icd_ids,
      dos,
      provider_id,
      charge_id
    };
    return this.http.post(global.url + API_URL.MIPS.calculateDenominator, { details });
  }
  getQuestionnareResultsForPhrases(charge_id, company_id) {
    const details = {
      ...this.authService.authObject,
      company_id,
      charge_id
    };
    return this.http.post(global.url + API_URL.MIPS.getQuestionnareResultsForPhrases, { details });
  }
  saveQuestionnareResults(questionnareResults, charge_id, company_id) {
    const details = {
      ...this.authService.authObject,
      company_id,
      questionnareResults,
      charge_id,
    };
    return this.http.post(global.url + API_URL.MIPS.saveQuestionnareResults, { details });
  }
  draftQuestionnareResults(questionnareResults, charge_id, company_id) {
    console.log("questionnaireResults : ", questionnareResults);

    const details = {
      ...this.authService.authObject,
      company_id,
      questionnareResults,
      charge_id,
    };
    return this.http.post(global.url + API_URL.MIPS.draftQuestionnareResults, { details });
  }
  getDraftQuestionnareResults(charge_id, company_id) {
    const details = {
      ...this.authService.authObject,
      company_id,
      charge_id
    };
    return this.http.post(global.url + API_URL.MIPS.getDraftQuestionnareResults, { details });
  }
  getQuestionnareResults(charge_id, company_id) {
    const details = {
      ...this.authService.authObject,
      company_id,
      charge_id
    };
    return this.http.post(global.url + API_URL.MIPS.getQuestionnareResults, { details });
  }

  setQuestionnairesResult(questionaireResults: QuestionaireResult[]) {
    this.questionnaireResults.next(questionaireResults);
  }

  getMipsAudit() {

  }
}
